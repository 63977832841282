import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaw6qR4z2Wew4AepPSmBcr0Ch6utTa8k4",
  authDomain: "personal-website-714f9.firebaseapp.com",
  projectId: "personal-website-714f9",
  storageBucket: "personal-website-714f9.appspot.com",
  messagingSenderId: "47282255895",
  appId: "1:47282255895:web:7990f39766c4f6bd478452",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
